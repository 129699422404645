import React from 'react';
import Validation from 'react-validation';
import validator from 'validator';

const phoneRegex = new RegExp('^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$');
const charLimit = 50;

// Define field objects
const allFields = [
  { input_name: "first_name", label_text: "First name", input_type: "text", validation: ['required', 'char_limit'] },
  { input_name: "last_name", label_text: "Last name", input_type: "text", validation: ['required', 'char_limit'] },
  { input_name: "phone_number", label_text: "Phone number", input_type: "text", validation: ['required', 'telephone'] },
  { input_name: "email_address", label_text: "Email address", input_type: "text", validation: ['required', 'email'] },
  {
    input_name: "which_campaign", label_text: "Which campaign are you fundraising for?", input_type: "select", validation: ['required', 'campaign_select'],
    options: {
      '0': 'Please make a selection',
      'rnd': 'Red Nose Day',
      'sr': 'Sport Relief'
    }
  },
  { input_name: "organisation_name", label_text: "Organisation name", input_type: "text", validation: [] },
  {
    input_name: "organisation_type", label_text: "Who is paying in?", input_type: "select", validation: ['required', 'org_select'],
    options: {
      '0': 'Please make a selection',
      'early_years': 'Early Years',
      'primary': 'Primary',
      'secondary': 'Secondary',
      'workplace': 'Workplace',
      'myself_friends_family_or_community_group': 'Myself, friends, family or community group'
    }
  },
];

// Create an object to set up validation rules used by the form elements
Object.assign(Validation.rules, {
  required: {
    rule: value => { return value.toString().trim(); },
    hint: value => { return <span className='form-error is-visible'>This field is required.</span> }
  },
  char_limit: {
    rule: value => { return (value.toString().length <= charLimit); },
    hint: value => { return <span className='form-error is-visible'>Please limit to {charLimit} characters or less</span> }
  },
  telephone: {
    rule: value => { return (value.toUpperCase().match(phoneRegex) || value === ''); },
    hint: value => { return <span className='form-error is-visible'>Please enter a valid UK phone number</span> }
  },
  email_address: {
    rule: value => { return validator.isEmail(value) },
    hint: value => { return <span className='form-error is-visible'>Please enter a valid email address</span> }
  },
  org_select: {
    rule: value => { return (value !== '0'); },
    hint: value => { return <span className='form-error is-visible'>Please select the type of your organisation</span> }
  },
  campaign_select: {
    rule: value => { return (value !== '0'); },
    hint: value => { return <span className='form-error is-visible'>Please select a campaign</span> }
  },
});

export { Validation, allFields };
