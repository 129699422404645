import React, { Component } from 'react';
import PropTypes from 'prop-types'; // ES6

class CategorySelect extends Component {

  static defaultProps = {
    options: [
      {value: "general", label: "General"},
      {value: "complaints", label: "Complaints"},
      {value: "donations", label: "Donations"},
      {value: "fundraising", label: "Fundraising"},
      {value: "grants", label: "Grants and funding enquiries"},
      {value: "safeguarding", label: "Safeguarding"},
      {value: "schools", label: "Schools, youth groups and nurseries"},
      {value: "shop", label: "Shop orders"},
    ],
  };

  render() {
    return (
      <div className="form-item">
        <label htmlFor="category-select">Select a category for your query</label>
        <select id="category-select" className="form__select" value={this.props.currentOption} onChange={ this.props.handleCategoryChange }>
          {this.props.options.map(function(thisOption,index){
            return (
              <option key={index} value={thisOption.value}>{thisOption.label}</option>)
          })}
        </select>
      </div>
    )
  };
}

CategorySelect.PropTypes = {
  handleChange: PropTypes.func.isRequired,
  currentOption: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
};

export default CategorySelect;
