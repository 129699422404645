import React from 'react';
import ReactDOM from 'react-dom';
import ContactUs from './components/ContactUs/ContactUs';
import GiroForm from './components/Giro/Giro';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';

ReactDOM.render(
  <Router>
  <Switch>
    <Route exact path="/" component={ContactUs} />
    <Route exact path="/giro-request" component={GiroForm} />
    <Redirect push to="/" />
  </Switch>
</Router>,
  document.getElementById('contact-form')
);
