import React, { Component } from 'react';
import PropTypes from 'prop-types'; // ES6
import Validation from 'react-validation';

const textAreaMax = 3000;
const postcodeRegex = new RegExp('^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]?' +
  ' {0,1}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$');
const phoneRegex = new RegExp('^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$');

const charLimit = 50;

// Define a global version of the current email field value, so its accessable by the Validation object without needing to pass in the state
let currentEmail, confirmEmail = "";

let validator = require('validator');

/* Create an object to set up validation rules used by the form elements */
Object.assign(Validation.rules, {
  required: {
    rule: value => { return value.toString().trim(); },
    hint: value => { return <span className='form-error is-visible'>This field is required.</span> }
  },
  email: {
    rule: value => {
      currentEmail = value.toLowerCase();
      return validator.isEmail(currentEmail);
    },
    hint: value => { return <span className='form-error is-visible'>Please enter a valid email address.</span> }
  },
  confirm_email: {
    rule: value => {
      confirmEmail = value.toLowerCase();
      return validator.equals(confirmEmail, currentEmail);
    },
    hint: value => { return <span className='form-error is-visible'>Please confirm your email as it appears above</span> }
  },
  maxlength: {
    rule: value => { return (value.toString().length < textAreaMax); },
    hint: value => { return <span className='form-error is-visible'>Please ensure your message is {textAreaMax} characters or fewer</span> }
  },
  event_select: {
    rule: value => { return (value !== '0'); },
    hint: value => { return <span className='form-error is-visible'>Please select an event</span> }
  },
  shirt_select: {
    rule: value => { return (value !== '0'); },
    hint: value => { return <span className='form-error is-visible'>Please select your preference and size</span> }
  },
  event_place_select: {
    rule: value => { return (value !== '0'); },
    hint: value => { return <span className='form-error is-visible'>Please select your event place status</span> }
  },
  postcode: {
    rule: value => { return ( value.toUpperCase().match(postcodeRegex)); },
    hint: value => { return <span className='form-error is-visible'>Please enter a valid postcode</span> }
  },
  telephone: {
    rule: value => { return ( value.toUpperCase().match(phoneRegex) || value === ''); },
    hint: value => { return <span className='form-error is-visible'>Please enter a valid UK phone number</span> }
  },
  char_limit: {
    rule: value => { return (value.toString().length <= charLimit); },
    hint: value => { return <span className='form-error is-visible'>Please limit to {charLimit} characters or less</span> }
  }
});

class BasicForm extends Component {

  static defaultProps = {
    inputErrorClass : "input--error",
    currentCategory : "general",

    /* Common fields across all categories */
    fields: [
      {input_name: "first_name", label_text: "First name", input_type: "text", validation:['required', 'char_limit']},
      {input_name: "last_name", label_text: "Last name", input_type: "text", validation:['required', 'char_limit']},
      {input_name:"email", label_text: "Email address", input_type: "email", validation:['required', 'email', 'char_limit']},
      {input_name:"confirm_email", label_text: "Confirm email address", input_type: "email", validation:['required', 'confirm_email', 'char_limit']},
      {input_name: "subject", label_text: "Subject", input_type: "text", validation:['required']},
    ],

    /* Special fields that are specific to certain categories */
    special_fields : {
      shop: [
        {input_name:"order_number", label_text:"Order number", input_type:"text", validation:['required']}],
      grants: [
        {input_name:"grant_reference_number",label_text:"Grant Reference Number",input_type:"text", validation: ['required']}],
      site_problems: [
        {input_name :"browser", label_text: "Browser", input_type: "text", validation:['char_limit']},
        {input_name :"device", label_text: "Device", input_type: "text", validation:['char_limit']}],
      events_fundraising: [
        {input_name: "address_1", label_text: "Address line 1", input_type: "text", validation:['required', 'char_limit']},
        {input_name: "address_2", label_text: "Address line 2", input_type: "text", validation:['char_limit']},
        {input_name: "address_3", label_text: "Address line 3", input_type: "text", validation:['char_limit']},
        {input_name: "town_city", label_text: "Town/city", input_type: "text", validation:['required', 'char_limit']},
        {input_name: "postcode", label_text: "Postcode", input_type: "text", validation:['required','postcode']},
        {input_name: "telephone", label_text: "Phone number", input_type: "text", validation:['telephone']},
        {input_name :"event_place_select", label_text: "We have guaranteed places in some of the UK's best races so become a Sport Relief Superstar today and help end poverty and inequality. Do you wish to use a guaranteed place or have you already got a place?", input_type: "select", validation:['required','event_place_select'],
          options:{
            '0':'Please select your event place status',
            'I need a Sport Relief guaranteed place in an event':'I need a Sport Relief guaranteed place in an event',
            'I have my own place in an event':'I have my own place in an event'
        }},
        {input_name :"event_select", label_text: "Which event do you want to run in?", input_type: "select", validation:['required','event_select'],
          options:{
            '0':'Please select an event',
            'Hampton Court Palace Half Marathon 15th March':'Hampton Court Palace Half Marathon 15th March',
            'Liverpool Half 15th March':'Liverpool Half 15th March',
            'Liverpool 10 miler 15th March':'Liverpool 10 miler 15th March',
            'Bath Half Marathon 15th March':'Bath Half Marathon 15th March',
            'Paris Marathon 5th April':'Paris Marathon 5th April',
            'Manchester Marathon 5th April':'Manchester Marathon 5th April',
            'Brighton marathon 19th April':'Brighton marathon 19th April',
            'Brighton Half marathon 19th April':'Brighton Half marathon 19th April',
            'Brighton 10K 19th April':'Brighton 10K 19th April',
            'Hackney Half 17th May':'Hackney Half 17th May',
            'Edinburgh Marathon 24th May':'Edinburgh Marathon 24th May',
            'Edinburgh Half Marathon 24th May':'Edinburgh Half Marathon 24th May',
            'Wales 10K 5th July':'Wales 10K 5th July',
            'Yorkshire Marathon 18th Oct':'Yorkshire Marathon 18th Oct',
            'Amsterdam Marathon 18th Oct':'Amsterdam Marathon 18th Oct',
            'Amsterdam Half Marathon 18th Oct':'Amsterdam Half Marathon 18th Oct',
            'other':'Other'
        }},
        {input_name: "how_much_money", label_text: "How much money do you hope to raise?", input_type: "text", validation:['char_limit']},
        {input_name: "hear_about_us", label_text: "How did you hear about us?", input_type: "text", validation:['char_limit']},
        {input_name :"shirt_select", label_text: "We provide a free technical Sport Relief running top to all of our Sport Relief Superstars. Please indicate your preference and size?", input_type: "select", validation:['required','shirt_select'],
          options:{
            '0':'Please select your preference and size',
            'Not required':'Not required',
            'XS T-shirt':'XS T-shirt',
            'XS Vest':'XS Vest',
            'S T-shirt':'S T-shirt',
            'S Vest':'S Vest',
            'M T-shirt':'M T-shirt',
            'M Vest':'M Vest',
            'L T-shirt':'L T-shirt',
            'L Vest':'L Vest',
            'XL T-shirt':'XL T-shirt',
            'XL Vest':'XL Vest',
            'XXL T-shirt':'XXL T-shirt',
            'XXL Vest':'XXL Vest'
          }
        }
      ],
    },
  };

  renderSpecialFields() {

    let specialField = this.props.special_fields[this.props.currentCategory];

    if (specialField) {

      /* To store every field so we can return them all in one go */
      let specialFields = [];

      /* Loop through each object to print out all extra fields for that category, adding it to our markup array */
        specialField.map(function (thisField, index) {

          let thisInput, thisWrappedInput

          /* Create a select field if it's valid */
          if (thisField.input_type === 'select' && thisField.options) {

            thisInput =
              <Validation.components.Select
                errorContainerClassName={this.props.inputErrorClass}
                ref={thisField.input_name}
                value=""
                type={thisField.input_type}
                name={thisField.input_name}
                id={thisField.input_name}
                className="special-field"
                validations={thisField.validation}
                onChange={this.props.handleSelectChange}
                >
                {Object.keys(thisField.options).map(key => (
                  <option value={key} key={key}>{thisField.options[key]}</option>
                ))}
              </Validation.components.Select>
          } else {
            thisInput =
              <Validation.components.Input
                errorContainerClassName={this.props.inputErrorClass}
                ref={thisField.input_name}
                value=""
                type={thisField.input_type}
                name={thisField.input_name}
                id={thisField.input_name}
                className="special-field"
                validations={thisField.validation}
                onChange={this.props.handleTyping}/>
          }

          // Wrap the inputs with common markup to avoid repetition
          thisWrappedInput =
            <div className="form-item" key={index}>
              <label htmlFor={thisField.input_name} className={thisField.validation[0]}>{thisField.label_text}</label>
              {thisInput} </div>

          specialFields.push(thisWrappedInput)

        }.bind(this))


      return specialFields;
    }
  }

  render(){

    const isEventsFundraising = this.props.currentCategory === 'events_fundraising';

    return (
      <div>
        <Validation.components.Form noValidate onSubmit={this.props.handleSubmit} ref={c => { this.form = c }}>

          {/* Loop through each of our standard fields */}
          {this.props.fields.map(function(thisField,index){

            const input_name = thisField.input_name;

            /* Nasty edge-case check to skip Subject field from our common fields for Events Fundraising */
             return(
               isEventsFundraising && input_name === 'subject' ? null :
                 <div className={"form-item " + input_name} key={index}>
                 <label htmlFor={input_name} className={thisField.validation[0]}>{thisField.label_text}</label>
                 <Validation.components.Input
                   value=""
                   errorContainerClassName={this.props.inputErrorClass}
                   ref={input_name}
                   type={thisField.input_type}
                   name={input_name}
                   id={input_name}
                   className="basic-field"
                   validations={thisField.validation}
                   onChange={this.props.handleTyping} />
               </div>
              )
          }.bind(this))}

          {/* Custom function to render any additional fields for this category */}
          {this.renderSpecialFields(this)}

          {/* Only render the Message for the relevant categories */}
          { !isEventsFundraising ?

              <div className="form-item">
                <label htmlFor="message" className="required">Message</label>
                <Validation.components.Textarea
                  errorContainerClassName={this.props.inputErrorClass}
                  maxLength="3000"
                  value=""
                  id="message"
                  name="message"
                  validations={['required', 'maxlength']}
                  onChange={this.props.handleTyping}/>
            </div> :
            <p className="age-confirm">
              By registering for Sport Relief Superstars, you confirm you are over 18 years old,
              and in order to manage your registration we will hold your name, email address and any supporting
              information you give us on our systems.
              The Fundraising Regulator requires us to keep your personal data for 6 years
              so that we can report on the number of complaints and enquiries we receive.
              Your personal data is not passed to the regulator. We’ll keep your data safe, we won’t sell it,
              and we won’t use it for marketing.</p>
          }

          <Validation.components.Button type="submit" value="Submit" className="btn">Submit</Validation.components.Button>
        </Validation.components.Form>
      </div>
    )
  }
}

BasicForm.PropTypes = {
  handleTyping: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSpecialFields: PropTypes.func.isRequired,
  renderSpecialFields: PropTypes.func.isRequired,
  currentCategory: PropTypes.string.isRequired,
  validateAllFields: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    label_text: PropTypes.string.isRequired,
    input_type: PropTypes.string.isRequired,
    input_name: PropTypes.string.isRequired,
  })).isRequired,
};

export default BasicForm;

