import React, { Component } from 'react';
import PropTypes from 'prop-types'; // ES6
import TagManager from 'react-gtm-module';
import '../../css/styles.scss';
import CategorySelect from '../CategorySelect/CategorySelect';
import BasicForm from '../BasicForm/BasicForm';
import DOMPurify from 'dompurify'
import { createCORSRequest } from '../../HelperFunctions/HelperFunctions';

// Get the endpoint url from environment settings
const endpoint_url = process.env.REACT_APP_ENDPOINT_URL + 'contact/submit';

class ContactUs extends Component {

  static defaultProps = {
    submissionCopy: {
      default: "",
      complaints: "We aim to get back in touch with you within 2 weeks.",
    },
    customSubjectLine: {
      default: ""
    },
  };

  /* Use this rather than deprecated getInitialState */
  constructor(props) {
    super(props);
    this.state = {
      submissionComplete: false,
      form_values : {
        category: "general",
      },
    };
  };

  /* Helper function to return URL query parameter */
  getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    /* Hide the select box */
    document.getElementById('contact-select--wrapper').style.display = "none";

    /* Grab parameters */
    let params = decodeURIComponent(results[2].replace(/\+/g, " "));

    let subject = this.props.customSubjectLine[params];

    /* Update the Subject field accordingly */
    if (subject) {
      this.refs.contactform.refs.subject.state.value = subject;
      let stateCopy = Object.assign({}, this.state);
      stateCopy.form_values.subject = subject;
      this.setState(stateCopy);
    }

    return params;
  }

  /**
   * Trigger when component mounts.
   */
  componentDidMount() {
    /* Change our category state based on url query */
    let stateCopy = Object.assign({}, this.state);

    let urlCategory = this.getParameterByName('category');

    if (urlCategory !== null && typeof urlCategory !== 'undefined') {
      stateCopy.form_values.category = urlCategory;
    }

    this.setState(stateCopy);

    let state = this;
    setTimeout(function () {
      state.sendFormHeightMessage();
    }, 250);
  }

  /**
   * Trigger when component updates;
   */
  componentDidUpdate() {
    this.sendFormHeightMessage();
  }

  /**
   * Send form height message to parent iframe.
   */
  sendFormHeightMessage() {
    const formHeight = document.getElementById('contact-form') !== null
      ? document.getElementById('contact-form').clientHeight
      : '1000';
    window.parent.postMessage('{"iframe_height":"' + formHeight + '"}','*');
  }

  /* Update the fields and the state when we're typing into the input fields */
  handleTyping(e){
    let stateCopy = Object.assign({}, this.state);
    stateCopy.form_values[e.target.id] = e.target.value;
    this.setState(stateCopy);
  }

  /* Update the fields and the state when we're changing select fields */
  handleSelectChange(e){
    var stateCopy = Object.assign({}, this.state);
    stateCopy.form_values[e.target.id] = e.target.value;
    this.setState(stateCopy);
  }

  /* Update the application state when we're selected a new category */
  handleCategoryChange(event) {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.form_values["category"] = event.target.value;
    this.setState(stateCopy);
  };

  handleSubmit(e, callback) {

    e.preventDefault();

    let xhr = createCORSRequest('POST', endpoint_url);

    if (!xhr) {
      throw new Error('CORS not supported');
    }

    xhr.onerror = function() {
      console.log('There was an error');
    };

    xhr.onreadystatechange = function() {
      if (xhr.status === 200 && xhr.readyState === 4) {
        /* If the submission is successful, run the 'handleSuccess' callback function we passed into the constructor */
        callback();
      }
    };

    /* Construct the customised 'subject' entry for submission (and also 'message' to keep the BE
     happy) */
    if (this.state.form_values['category'] === 'events_fundraising') {
      const customSubject = this.state.form_values['first_name'] + ' ' + this.state.form_values['last_name']  + ': ' + this.state.form_values['event_select'];
      let stateCopy = Object.assign({}, this.state);
      stateCopy.form_values['subject'] = customSubject;
      stateCopy.form_values['message'] = customSubject;
      this.setState(stateCopy);
    }

    // DEBUG
    console.log('Submitting:', this.state.form_values);

    xhr.send(JSON.stringify(this.state.form_values));

    // Pass 'scroll back to top of iframe' message to Drupal on any submission, successful or not
    window.parent.postMessage('{"back_to_top":"true"}','*');
  };

  handleSuccess() {
    let stateCopy = Object.assign({}, this.state);
    stateCopy.submissionComplete = true;

    /* Pass email address to iframe parent (Drupal etc) */
    window.parent.postMessage('{"email_address":"' + stateCopy.form_values.email + '"}','*');

    this.setState(stateCopy);
    this.successDataLayer();
  };

  /**
   * Success page GTM update
   */
  successDataLayer() {
    TagManager.dataLayer({
      dataLayer: {
        formDesc: 'contactus',
        formSubmit: this.state.submissionComplete ? 'Success' : 'Failed',
        event: 'formSubmission'
      },
    });
  }

  render() {

    let copy = this.props.submissionCopy["default"];

    /* See if we've got prescribed copy for this category */
    if ( String(this.state.form_values["category"]) in this.props.submissionCopy ) {
      copy = this.props.submissionCopy[ this.state.form_values["category"] ];
    }

    if (!this.state.submissionComplete) {
      return (
        <div className="App">
            <div id="contact-select--wrapper" className="contact-select--wrapper">
              <CategorySelect
                currentOption={this.state.selectedCategory}
                handleCategoryChange={function(e){
                  this.handleCategoryChange(e);
                }.bind(this)}/>
            </div>

            <div className="contact-form--wrapper">
              <BasicForm
                ref="contactform"
                currentCategory={this.state.form_values["category"]}
                handleSpecialFields={this.handleSpecialFields}
                handleSubmit={function(e){
                  this.handleSubmit(e, this.handleSuccess.bind(this));
                }.bind(this)}
                handleTyping={function(e){this.handleTyping(e);}.bind(this)}
                handleSelectChange={function(e){this.handleSelectChange(e);}.bind(this)} />
              </div>
          </div>
      );
    }

    else {

      return (
        <div className="App App--success">
          <h1 className="title">
          { this.state.form_values["category"] === 'events_fundraising' ? 'Thank you for being a Sport Relief' +
            ' Superstar, and choosing to support Sport Relief!'
          : "Thanks for getting in contact with us, " + this.state.form_values["first_name"] + "."
          }
          </h1>

          {/* Only print the surrounding markup if our 'additional copy' variable isn't empty */}
          { Boolean(copy) ? <h3 className="title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(copy)}}></h3> : null }
        </div>
      )
    }
  }
}

/* Define proptypes */
ContactUs.PropTypes = {
  thisForm: PropTypes.object.isRequired,
  handleSpecialFields: PropTypes.func.isRequired,
  handleTyping : PropTypes.func.isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  submissionCopy: PropTypes.arrayOf(PropTypes.shape({
    copy: PropTypes.string.isRequired,
  })).isRequired,
};

export default ContactUs;
