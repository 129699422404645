import TagManager from 'react-gtm-module';

const createCORSRequest = (method, url) => {
  let xhr = new XMLHttpRequest();
  if ("withCredentials" in xhr) {
    // Check if the XMLHttpRequest object has a "withCredentials" property.
    // "withCredentials" only exists on XMLHTTPRequest2 objects.
    xhr.open(method, url, true);

  } else if (typeof XDomainRequest !== "undefined") {
    // Otherwise, check if XDomainRequest.
    // XDomainRequest only exists in IE, and is IE's way of making CORS requests.
    xhr = new XDomainRequest();
    xhr.open(method, url);

  } else {
    // Otherwise, CORS is not supported by the browser.
    xhr = null;
  }
  xhr.setRequestHeader("Content-Type", "application/json");

  return xhr;
};

const successDataLayer = (submissionComplete) => {
  TagManager.dataLayer({
    dataLayer: {
      formDesc: 'giro',
      formSubmit: submissionComplete ? 'Success' : 'Failed',
      event: 'formSubmission'
    },
  });
};

const sendFormHeightMessage = () => {
  const formHeight = document.getElementById('contact-form') !== null
    ? document.getElementById('contact-form').clientHeight
    : '1000';
  window.parent.postMessage('{"iframe_height":"' + formHeight + '"}', '*');
};

  // To ensure parity with Contact Service and data
const updateFormValueKeys = (formValues) => {
    formValues['address_1'] = formValues['address1'];
    formValues['address_2'] = formValues['address2'];
    formValues['address_3'] = formValues['address3'];
    formValues['postal_code'] = formValues['postcode'];
    delete formValues['address1']; delete formValues['address2'];
    delete formValues['address3']; delete formValues['postcode'];
    return formValues;
  };

export { createCORSRequest, successDataLayer, sendFormHeightMessage, updateFormValueKeys };
