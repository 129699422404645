import React, { Component } from 'react';
import '../../css/styles.scss';
import { Validation, allFields } from './ValidationRules';
import { createCORSRequest, successDataLayer, sendFormHeightMessage, updateFormValueKeys } from '../../HelperFunctions/HelperFunctions';
import PostcodeLookup from '@comicrelief/storybook/src/components/PostcodeLookup/PostcodeLookup';

const endpoint_url = process.env.REACT_APP_ENDPOINT_URL + 'giro-request';

const CAMPAIGN_CODE = 'RND24';

class GiroForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form_values: {
        campaign: CAMPAIGN_CODE,
        postcode: null,
        address1: null,
        address2: null,
        address3: null,
        town: null,
        country: null,
        which_campaign: null
      },
      submissionComplete: false,
      isMainFormValid: false,
      isAddressValid: false,
      addressValidity: {
        postcode: null,
        address1: null,
        address2: null,
        address3: null,
        town: null,
        country: null
      }
    }
  }

  componentDidMount() {
    // Crummy timeout to help us assume the render has *actually* finished
    setTimeout(function () {
      sendFormHeightMessage();
    }, 1000);
  };

  componentDidUpdate() {
    sendFormHeightMessage();
  };

  /* Update the fields and the state when we're typing into the input fields */
  handleTyping(e) {
    let stateCopy = Object.assign({}, this.state);
    stateCopy.form_values[e.target.id] = e.target.value;
    // Use the callback to check the current status of our form validity
    this.setState(stateCopy, function () {
      this.checkMainFormValidity();
    });
  }

  /* Update the fields and the state when we're changing select fields */
  handleSelectChange(e) {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.form_values[e.target.id] = e.target.value;
    this.setState(stateCopy, function () {
      this.checkMainFormValidity();
    });
  }

  handleSubmit(e, callback) {
    e.preventDefault();
    let xhr = createCORSRequest('POST', endpoint_url);

    if (!xhr) throw new Error('CORS not supported');

    xhr.onerror = function () {
      console.log('There was an error');
    };

    xhr.onreadystatechange = function () {
      if (xhr.status === 200 && xhr.readyState === 4) {
        /* If the submission is successful, run the 'handleSuccess' callback function we passed into the constructor */
        callback();
      }
    };

    const updatedFormValues = updateFormValueKeys(this.state.form_values);

    xhr.send(JSON.stringify(updatedFormValues));

    // Pass 'scroll back to top of iframe' message to Drupal on any submission, successful or not
    window.parent.postMessage('{"back_to_top":"true"}', '*');
  };


  checkMainFormValidity() {
    let stateCopy = Object.assign({}, this.state);
    stateCopy.isMainFormValid = Object.entries(this.form.state.errors).length === 0;
    this.setState(stateCopy);
  }

  handleSuccess() {
    let stateCopy = Object.assign({}, this.state);
    stateCopy.submissionComplete = true;
    this.setState(stateCopy);
    successDataLayer(this.state.submissionComplete);
  };

  checkAddressValidation(validationObj, name) {
    const stateCopy = Object.assign({}, this.state);

    // Update our state only if the PCLU validation has changed
    if (stateCopy.form_values[name] !== validationObj.value) {
      stateCopy.form_values[name] = validationObj.value;

      // Store validity in separate array for ease of checking
      stateCopy.addressValidity[name] = validationObj.valid;

      // Run second check as a callback on the same cached state obj
      this.setState(stateCopy, function () {
        const allValidities = Object.values(this.state.addressValidity);
        // Set the overall 'address validity' based on all the field validites
        stateCopy.isAddressValid = !(allValidities.includes(null) || allValidities.includes(false));
        this.setState(stateCopy);
      });
    }
  };

  render() {
    if (!this.state.submissionComplete) {
      let thisCurrentField = null;
      return (
        <div className="App">
          <div className="giro-form--wrapper">
            {/* Remove HTML5 validation from form too */}
            <Validation.components.Form
              noValidate="novalidate"
              onSubmit={function (e) { this.handleSubmit(e, this.handleSuccess.bind(this)); }.bind(this)}
              ref={c => { this.form = c }} >
              {allFields.map(function (thisField, index) {

                thisField.input_type === 'text' ?
                  thisCurrentField =
                  <div className={"form-item " + thisField.input_name} key={index}>
                    <Validation.components.Input
                      value=""
                      errorContainerClassName={'input--error'}
                      ref={thisField.input_name}
                      type={thisField.input_type}
                      name={thisField.input_name}
                      id={thisField.input_name}
                      className="basic-field"
                      validations={thisField.validation}
                      onChange={function (e) { this.handleTyping(e); }.bind(this)} />
                  </div>
                  : thisCurrentField = <div className={"form-item " + thisField.input_name} key={index}>
                    <Validation.components.Select
                      errorContainerClassName={this.props.inputErrorClass}
                      ref={thisField.input_name}
                      value=""
                      type={thisField.input_type}
                      name={thisField.input_name}
                      id={thisField.input_name}
                      className="special-field"
                      validations={thisField.validation}
                      onChange={function (e) { this.handleSelectChange(e); }.bind(this)}
                    >
                      {Object.keys(thisField.options).map(key => (
                        <option value={key} key={key}>{thisField.options[key]}</option>
                      ))}
                    </Validation.components.Select>
                  </div>

                return (
                  <div className={"form-item " + thisField.input_name} key={index}>
                    <label htmlFor={thisField.input_name} className={thisField.validation[0]}>{thisField.label_text}</label>
                    {thisCurrentField}
                  </div>
                )
              }.bind(this))}

              <PostcodeLookup
                ref={'pclu'}
                label="Postal address"
                showErrorMessages={false}
                forceManualInput={false}
                isAddressValid={
                  (validation) => {
                    Object.keys(validation).map(key => this.checkAddressValidation(validation[key], key));
                  }
                }
              />

              <Validation.components.Button id="giro-submit" children="null" type="submit" value="Submit" className="btn"
                disabled={!(this.state.isMainFormValid && this.state.isAddressValid)}
              >Submit</Validation.components.Button>
            </Validation.components.Form>

          </div>
        </div>
      )
    } else {
      return (
        <div className="App App--success">
          <h1 className="title">
            Giro request submitted
          </h1>
        </div>
      );
    }
  };
}
export default GiroForm;
